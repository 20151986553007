import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"

const active = {
  title: "AGBs"
}

const AGBs = () => 
<DefaultLayout image="/assets/images/IMG_8128.jpeg" active={active}>
  <TwoThirdsGrid textRight>
    <div>
      <HeadlineWithSub>
        <h1>AGB</h1>
      </HeadlineWithSub>
    </div>
    <Text>
      <p>
        <strong>1. Geltungsbereich</strong><br />
        Diese AGB gelten <br />

        a) für sämtliche Kurse, die von Strichpunkt, St. Gallen/Mogelsberg, organisiert sind<br />
        b) für sämtliche Termine, die mit Strichpunkt, St. Gallen/Mogelsberg vereinbart wurden. <br />

        Mit der Anmeldung zu einem Kurs oder einem Termin erkennt der Teilnehmende oder der/die Klientin die Geschäftsbedingungen an.
      </p>
      
      <p>
       <strong>2. Anmeldung</strong><br />
        a) Kursanmeldungen sind verbindlich. Mit der Zusendung der Anmeldebestätigung und der Rechnung gilt der Vertrag über die Kursteilnahme als abgeschlossen. Ihre Anmeldung wird in der Reihenfolge des Eingangs berücksichtigt.<br />
        Im Falle der Überbuchung wird die Anmeldende schnellstmöglich informiert; ein Vertrag kommt in diesem Fall nicht zustande.Nur vor Seminar-/Kursbeginn eingegangene Zahlungen berechtigen  zur Kursteilnahme.<br />
        b) Die Termine werden in gegenseitiger Absprache festgelegt und sind beidseitig verbindlich. 
      </p>
      <p>
        <strong>3. Stornierung</strong><br />
        a) Kursabmeldungen müssen schriftlich bis 7 Tage vor Beginn des Kurses erfolgen. Dabei fallen Fr. 30.— Bearbeitungsgebühr an. Bis 3 Tage vor Kursbeginn fallen 50 % der Kurskosten an. Bei Abmeldungen später als 3 Tage vor Kursbeginn werden die vollen Kurskosten verrechnet. <br />
        In jedem Fall kann ein Ersatzteilnehmer gestellt werden, so dass lediglich eine Bearbeitungsgebühr von Fr. 30.-- entstehen.
        Es besteht kein Anspruch auf Ersatz eines versäumten Veranstaltungstages. <br />
        b) Termine: Im Verhinderungsfall muss der Termin mindestens 24h vor dem vereinbarten Zeitpunkt telefonisch oder schriftlich abgesagt werden. Für verspätete Abmeldungen wird ein Stundenhonorar in Rechnung gestellt.
      </p>
      <p>
        <strong>4. Absage durch Strichpunkt</strong><br />
        a) Sollte ein Kurs aus wichtigen Gründen seitens von Strichpunkt abgesagt werden müssen, so besteht Anspruch auf volle Rückerstattung der Teilnahmegebühr. Ansprüche darüber hinaus bestehen nicht. <br />
        b) Im Verhinderungsfall aus wichtigen Gründen behält sich Strichpunkt vor, einen Termin abzusagen. Es wird dabei ein Ersatztermin angeboten. 
      </p>
      <p>
        <strong>5. Vertraulichkeit</strong><br />
        a) Für Kursleitende sowie für Teilnehmer*innen gilt die Schweigepflicht über Kenntnisse, die über andere Personen, insbesondere Kursteilnehmende, in Erfahrung gebracht wurden, zu bewahren.<br />
        b) Alle Beratungs- und Therapiegespräche werden vertraulich behandelt. Informationen an Krankenkassen, Ärztinnen, etc.. werden nur im Einverständnis des/der Klienten/Klientin gegeben. 
      </p>
      <p>
        <strong>6. Haftungsausschluss</strong><br />
        Jeder Teilnehmer, jede Teilnehmerin trägt die volle Verantwortung für sich und seine/ihre Handlungen inner- und außerhalb der Kursgruppe und der Beratungs- und Therapietermine und kommt für verursachte Schäden selbst auf. Sämtliche Versicherungen (Krankheit, Unfall, Haftpflicht, Annullation) sind Sache der Teilnehmer*innen und Klient*innen.
      </p>
      <p>
        <strong>7. Änderungsvorbehalte</strong><br />
        Strichpunkt ist berechtigt, notwendige inhaltliche, methodische und organisatorische Änderungen oder Abweichungen vor oder während der Kurse vorzunehmen, soweit diese den Nutzen der angekündigten Veranstaltung für die Teilnehmenden nicht wesentlich ändern.
      </p>
      <p>
        <strong>8. Anwendbares Recht</strong><br />
        Zur Anwendung kommt ausschliesslich schweizerisches Recht.
        Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam oder ergänzungsbedürftig sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen unberührt. Die Vertragspartner werden anstelle der unwirksamen oder ergänzungsbedürftigen Bestimmungen eine neue Regelung vereinbaren, die dem gewollten wirtschaftlichen Zweck am nächsten kommt.
      </p>
    </Text>
  </TwoThirdsGrid>
</DefaultLayout>

export default AGBs