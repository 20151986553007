import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../../styles/variables"

const TextDiv = styled.article`
    width: 100%;
    p {
        margin-bottom: ${spacers.s}px;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        p {
            margin-bottom: 26px;
        }
    }
`

const Text = ({children}) => <TextDiv>{children}</TextDiv>

export default Text