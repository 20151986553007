import React, {useState} from "react"
import styled from "styled-components"
import { breakpoints, heights } from "../../styles/variables"

import Wrapper from "../layout/Wrapper"
import NavTitle from "./NavTitle"
import NavToggle from "./NavToggle"
import NavList from "./NavList"

const HeaderDiv = styled.div`
    height: ${heights.navDefault.s}px;
    width: 100%;
    background: white;
    position: fixed;
    box-shadow: #00000029 0px 3px 6px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        height: ${heights.navDefault.m}px;
    }
`

const NavDefault = ({active}) => {
    const [isOpen, setIsOpen] = useState(false)

    return(
        <HeaderDiv>
            <Wrapper xl>
                <NavTitle isOpen={isOpen} active={active} />
                <NavToggle isOpen={isOpen} setIsOpen={setIsOpen}/>
            </Wrapper>
            <NavList isOpen={isOpen} setIsOpen={setIsOpen}/>
        </HeaderDiv>
    )
}

export default NavDefault