import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles/variables"

const NavTitleDiv = styled.div`
display: flex;
height: 100%;
    a:hover {
        text-decoration: none;
    }

    h2 {
        font: 28px / 33px "Karla ExtraBold", sans-serif;
        margin: 15px 0 11px 0;
        color: black;
    }

    h6 {
        display: none;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        h2 {
            font: 36px / 42px "Karla ExtraBold", sans-serif;
            margin: 18px 27px 18px 0;
        }

        h6 {
            margin-top: 24px;
            display: block;
        }
    }
`

const NavTitle = ({active}) => <NavTitleDiv>
    <Link to="/">
        <h2>strichpunkt</h2>
    </Link>
    <h6>{active.title}</h6>
</NavTitleDiv>

export default NavTitle