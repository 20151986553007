import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { breakpoints, heights } from "../../styles/variables"
import Wrapper from "../layout/Wrapper"

const NavListNav = styled.nav`
    position: fixed;
    bottom: -100%;
    top: 100%;
    background: black;
    height: calc(100vh - ${heights.navDefault.s}px);
    width: 100vw;
    z-index: 0;
    transition: all 300ms;

    .signetDiv {
        background-image: url('/assets/gestaltungselemente/Strichpunkt-Signet-Negativ.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        width: 27px;
        height: 68px;
        right: 21px;
        bottom: 31px;
    }

    &.isOpen {
        bottom: 0;
        top: ${heights.navDefault.s}px;
    }
   
    > div {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        overflow-y: scroll;
        height: 100%;
    }

    a {
        color: white;

        &[aria-current="page"] {
            font-family: "Karla ExtraBold", sans-serif;
        }
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        height: calc(100vh - ${heights.navDefault.m}px);

        &.isOpen {
            bottom: 0;
            top: ${heights.navDefault.m}px;
        }

        .signetDiv {
            right: 51px;
            bottom: 32px;
        }
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        .signetDiv {
            width: 36px;
            height: 91px;
            bottom: 39px;
            right: 53px;
        }
    }
`

const NavList = ({ isOpen, setIsOpen, activeNav }) => {
    return (
        <NavListNav className={isOpen ? "isOpen" : ""}>
            <Wrapper>
                <Link to="/mal-und-gestaltungstherapie" onClick={() => setIsOpen(!isOpen)}>Mal- & Gestaltungstherapie</Link>
                <Link to="/coaching" onClick={() => setIsOpen(!isOpen)}>Coaching</Link>
                <Link to="/natur-und-ihre-bedeutung" onClick={() => setIsOpen(!isOpen)}>Natur und ihre Bedeutung</Link>
                <Link to="/weiterbildung" onClick={() => setIsOpen(!isOpen)}>Weiterbildung</Link>
                <Link to="/preise" onClick={() => setIsOpen(!isOpen)}>Preise</Link>
                <Link to="/garten" onClick={() => setIsOpen(!isOpen)}>Garten</Link>
                <Link to="/portrait" onClick={() => setIsOpen(!isOpen)}>Portrait</Link>
                <Link to="/literatur" onClick={() => setIsOpen(!isOpen)}>Literatur + Links</Link>
                <Link to="/kontakt" onClick={() => setIsOpen(!isOpen)}>Kontakt</Link>
            </Wrapper>
            <div className="signetDiv">

            </div>
        </NavListNav>)
}

export default NavList